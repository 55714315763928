.product-card-container{
    background-color: #ffffff;
    box-shadow: 0px 0px 3px rgb(226, 226, 226);
    .rx-container{
        top: 0;
        right: 0;
        z-index: 10;
        .rx-pill{
            border-radius: 15px 0px 0px 15px;
        }
    }
    .product-description {
        font-size: 15px;
        h1 {
            font-size: 25px;
        }
        h2, h3 {
            font-size: 18px;
            margin: 12px 0;
        }
        ul {
            margin: 10px 0;
        }
    }
    .add-to-cart{
        button, button:focus{
            border: none;
            outline: none;
            box-shadow: none;
            opacity: 0.95;
            color: #fff !important;
            background-color: #2b2f4c;
            font-size: 14px;
        }
        .icon{
            font-size: 16px;
        }
        .inc-dec{
            width: 30px;
            border-radius: 3px;
        }
    }
    .resize-image-div{
        width: 293px;
        height: 197px;
        img{
            width: 100%;
            height: 100%;
        }
    }
}