.search-container{
    color:#2b2f4c !important;
    .search-icon {
        position: absolute;
        left:10px;
        top: 7px;
        right: auto;
        font-size: 18px;
    }
    input{
        padding: 7px 10px 7px 45px;
        background-color: #f7f7f7 !important;
        border: 0;
        outline: 0;
        border-radius: 0.3rem;
        font-size: 14px;
        &::placeholder{
            color:#757575;
        }
    }
}