
.jss1 {
  width: 100%;
}
.jss2 {
  margin: auto;
  max-width: 1064px;
}
.jss3 {
  margin: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}
.jss4 {
  opacity: 0.6;
  font-size: 12px;
  text-decoration: line-through;
}
.jss5 {
  padding: 76px 40px 40px 40px;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
}
@media (max-width:767.95px) {
  .jss5 {
    top: 0;
    width: 100%;
    padding: 0;
    margin-top: 137px;
    border-radius: 0;
    padding-bottom: 20px;
  }
}
.jss6 {
  padding-top: 30px;
  border-bottom: 1px solid #02475b1a;
  padding-bottom: 8px;
  text-transform: capitalize;
}
@media (max-width:767.95px) {
  .jss6 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.jss6 ul {
  margin: 0;
  padding: 0;
}
.jss6 ul li {
  color: rgba(2, 71, 91, 0.804633);
  display: inline-block;
  padding: 0;
  position: relative;
  font-size: 12px;
  list-style: none;
  line-height: 16px;
  padding-right: 2em;
}
.jss6 ul li:last-child:after {
  display: none;
}
.jss6 ul li:after {
  right: 0;
  width: 2em;
  content: ">";
  display: inline-block;
  position: absolute;
  text-align: center;
}
@media (min-width:768px) {
  .jss7 {
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width:767.95px) {
  .jss7 {
    padding: 15px;
  }
}
.jss8 {
  top: 0;
  width: 100%;
  display: none;
  padding: 20px;
  z-index: 999;
  position: fixed;
  background: #fff;
  align-items: center;
}
@media (max-width:767.95px) {
  .jss8 {
    display: flex;
  }
}
.jss9 {
  width: 395px;
  background-color: #fff;
}
.jss9>div {
  background: #fff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin-bottom: 16px;
}
@media (max-width:767.95px) {
  .jss9 {
    width: 100%;
    padding: 0;
  }
}
@media (max-width:991.95px) {
  .jss10 {
    height: calc(100vh - 250px) !important;
  }
}
@media (max-width:767.95px) {
  .jss10 {
    height: auto !important;
    max-height: inherit !important;
  }
}
@media (max-width:767.95px) {
  .jss10 > div {
    max-height: inherit !important;
  }
}
.jss11 {
  cursor: pointer;
  margin-right: 50px;
}
@media (min-width:1220px) {
  .jss11 {
    top: 0;
    left: -82px;
    width: 48px;
    height: 48px;
    position: absolute;
    text-align: center;
    line-height: 36px;
    border-radius: 50%;
    background-color: #02475b;
  }
}
@media (max-width:767.95px) {
  .jss11 {
    margin-right: 0;
  }
}
.jss11 img {
  vertical-align: bottom;
}
.jss12 {
  vertical-align: middle;
}
@media (max-width:1219.95px) {
  .jss12 {
    display: none;
  }
}
.jss13 {
  flex: 1;
}
.jss14 {
  vertical-align: middle;
}
@media (min-width:1220px) {
  .jss14 {
    display: none;
  }
}
.jss15 {
  top: 149px;
  padding: 20px;
  position: sticky;
  border-radius: 5px;
  background-color: #fff;
}
@media (max-width:991.95px) {
  .jss15 {
    width: 100%;
  }
}
@media (max-width:767.95px) {
  .jss15 {
    padding: 20px;
    background-color: #f7f8f5;
  }
}
.jss16 {
  width: calc(100% - 290px);
  padding-left: 20px;
}
@media (max-width:991.95px) {
  .jss16 {
    width: 100%;
    padding-top: 20px;
  }
}
@media (max-width:767.95px) {
  .jss16 {
    padding-top: 0;
    padding-left: 0;
  }
}
.jss16 h2 {
  color: #02475b;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
}
.jss17 h1 {
  font-size: 21px;
  margin-top: 0;
}
@media (max-width:767.95px) {
  .jss18 {
    padding: 20px 0;
  }
}
.jss19 {
  color: #02475b;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.jss19 p {
  margin: 5px 0;
}
@media (max-width:767.95px) {
  .jss19 {
    margin: 0 20px;
    padding: 20px 0;
    border-top: 0.5px solid rgba(2,71,91,0.3);
  }
}
.jss20 {
  color: #02475b;
  font-size: 10px;
  font-weight: 500;
  padding-bottom: 8px;
  text-transform: uppercase;
}
.jss20 label {
  color: #658f9b;
  display: block;
  text-transform: none;
}
.jss21 {
  margin: 5px 0 0 0;
  min-height: auto;
  border-bottom: 0.5px solid rgba(2,71,91,0.3);
  border-radius: 0;
}
.jss21 svg {
  color: #02475b;
}
@media (max-width:767.95px) {
  .jss21 {
    border-bottom: none;
    background-color: #f7f8f5;
  }
}
.jss21:before {
  left: 20px;
  right: 20px;
  content: "";
  position: absolute;
}
.jss22 {
  color: #02475b;
  margin: 0 15px 0 0;
  opacity: 0.5;
  padding: 10px 0;
  min-width: auto;
  flex-basis: auto;
  min-height: auto;
  text-align: center;
  line-height: normal;
  text-transform: none;
}
.jss22 h2 {
  font-size: 14px;
  font-weight: 500;
}
.jss23 {
  opacity: 1;
}
.jss24 {
  height: 3px;
  background-color: #00b38e;
}
.jss250 {
  padding: 9px 13px 9px 13px;
  font-size: 13px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0, 0.2);
  font-weight: bold;
  border-radius: 5px;
}
.jss251 {
  color: #fff;
  background-color: #fcb716;
}
.jss251:hover {
  color: #fff;
  background-color: #fcb716;
}
.jss252 {
  color: #FC9916;
  background-color: #fff;
}
.jss253 {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
}
.jss25 {
  color: #0087ba;
  font-size: 14px;
  line-height: 22px;
}
@media (max-width:767.95px) {
  .jss25 {
    padding: 15px 20px;
    box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.3);
    background-color: #fff;
  }
}
.jss25 p {
  margin: 5px 0;
}
.jss26 {
  color: #0087ba;
  font-size: 14px;
  line-height: 22px;
}
.jss26 p {
  margin: 5px 0;
}
.jss26 ul {
  padding: 0 0 0 20px;
}
@media (max-width:767.95px) {
  .jss26 {
    padding: 20px;
    background-color: #fff;
  }
}
.jss27 {
  color: #02475b;
  display: flex;
  padding: 8px 12px;
  font-size: 12px;
  margin-top: 8px;
  align-items: center;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 16px;
  background-color: #f7f8f5;
}
.jss27>div>p>span {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  padding-left: 12px;
}
.jss28 {
  margin-left: auto;
  padding-left: 20px;
}
.jss29 {
  width: calc(100% - 395px);
  padding: 0px 0px 0px 40px;
  position: relative;
  border-radius: 5px;
}
@media (max-width:767.95px) {
  .jss29 {
    width: 100%;
    bottom: 0;
    padding: 0px;
    background-color: #fff;
  }
}
.jss29>div {
  padding: 15px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin-bottom: 16px;
}
.jss29>div>h2 {
  margin: 0 0 15px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
}
@media (max-width:767.95px) {
  .jss29>div>h2 {
    font-size: 20px;
  }
}
.jss30 {
  width: 100%;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  padding-bottom: 10px;
}
.jss31 {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
  background-color: #fed984;
}
.jss32 {
  color: #02475b;
  display: flex;
  padding: 10px;
  font-size: 14px;
  align-items: center;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 16px;
  justify-content: space-between;
  background-color: #f7f8f5;
}
.jss32 div {
  color: #02475B;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-left: auto;
  letter-spacing: 0.35px;
}
.jss32 h5 {
  color: #02475B;
  margin: 0;
  font-size: 10px;
  text-align: left;
  font-weight: 500;
  line-height: 13px;
}
@media (max-width:767.95px) {
  .jss32 {
    border-bottom: 0.5px solid rgba(2,71,91,0.3);
    border-radius: 0;
    background-color: transparent;
  }
}
.jss32 h5 span {
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.jss32 h5 img {
  width: 30%;
  display: inline-block;
  padding: 0px 2px;
  vertical-align: middle;
}
.jss32 h5 span span {
  color: #00B38E;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  padding-left: 13px;
}
.jss32 div small {
  color: #02475B;
  display: block;
  opacity: 0.6;
  font-size: 12px;
  text-align: right;
  font-weight: normal;
  line-height: 16px;
  margin-bottom: 4px;
  letter-spacing: 0.35px;
  text-decoration: line-through;
}
@media (max-width:767.95px) {
  .jss33 {
    margin: auto;
    text-align: center;
  }
}
.jss33 button {
  width: 100%;
}
@media (max-width:767.95px) {
  .jss33 button {
    width: auto;
    min-width: 240px;
  }
}
.jss34 {
  padding-top: 13px;
}
@media (max-width:767.95px) {
  .jss34 {
    padding: 20px;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.1);
    margin-left: -20px;
    margin-right: -20px;
    background-color: #fff;
  }
}
.jss35 {
  color: #0087ba;
  font-size: 14px;
  font-weight: 500;
}
.jss35 span:nth-child(1) {
  margin-right: 5px;
}
.jss36 {
  padding: 20px;
  text-align: center;
}
@media (max-width:899.95px) {
  .jss37 {
    display: none;
  }
}
.jss38 {
  border: 2px dashed #00B38E;
  display: flex;
  padding: 14px;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 0px 5px 20px rgba(128, 128, 128, 0.3);
  align-items: center;
  border-radius: 5px;
  margin-bottom: 20px;
  flex-direction: row;
}
.jss38 img {
  width: 18px;
  height: 18px;
}
.jss39 {
  font-size: 12px;
  font-weight: 600;
  padding-left: 6px;
}
.jss39 img {
  width: 29%;
  height: auto;
  vertical-align: middle;
}
.jss40 {
  padding: 0px !important;
  position: relative;
  margin-bottom: 20px;
}
.jss41 img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
.jss42 {
  top: 50%;
  left: 10px;
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  transform: translate(0px, -50%);
  align-items: center;
  flex-direction: column;
}
.jss43 {
  color: #FFFFFF;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  padding-bottom: 2px;
}
.jss44 {
  color: #FC9916;
  padding: 6px 15px;
  font-size: 10px;
  align-self: flex-start;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  line-height: 24px;
  border-radius: 5px;
}
.jss44 p {
  margin: 0;
  text-transform: uppercase;
}
.jss44 span {
  color: #02475B;
  display: block;
  font-size: 9px;
  margin-top: -5px;
  font-weight: 600;
  line-height: 11px;
}
.jss44 p img {
  width: 37%;
  vertical-align: middle;
}
.jss45 {
  padding: 0px 0px 0px 0px;
}
.jss45 >.slick-dots {
  margin: 0 0 0 40px;
  position: static !important;
  text-align: left;
}
.jss45 h2 {
  align-items: flex-start;
  flex-direction: column;
}
@media (max-width:899.95px) {
  .jss45 >.slick-dots {
    margin: 0;
    text-align: center;
  }
}
.jss45 >.slick-dots li {
  width: auto;
  height: auto;
}
.jss45 >.slick-dots li button {
  color: transparent;
  width: 8px;
  height: 8px;
  display: block;
  background: #C4C4C4;
  border-radius: 8px;
}
.jss45 >.slick-dots li.slick-active button {
  width: 20px;
  height: 8px;
  background: #007C9D;
}
.jss45 >.slick-dots li button:before {
  display: none;
}
.jss45 >.slick-list .slick-track {
  display: flex;
}
.jss45 >.slick-list .slick-slide {
  width: 100%;
  padding: 0;
}
.jss46 {
  padding: 20px;
}
@media (max-width:899.95px) {
  .jss46 {
    padding: 10px;
  }
}
@media (max-width:767.95px) {
  .jss46 {
    display: flex;
    align-items: center;
    justifycontent: center;
  }
}
.jss47 {
  padding: 10px 14px;
}
.jss48 {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.jss49 {
  margin: 0;
  font-size: 14px;
  margin-top: 0;
  line-height: 12px;
  margin-bottom: 5px;
  text-transform: initial;
}
.jss50 {
  margin: 0;
  font-size: 14px;
  line-height: 12px;
  text-transform: initial;
}
.jss51 {
  display: inherit;
}
.jss51 img {
  width: 40px;
}
.jss52 {
  width: 100%;
}
.jss54 {
  border-top: 1px solid rgba(0,0,0,0.05);
  margin-left: -20px;
  margin-right: -20px;
}
.jss55 {
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: row;
}
.jss55>div>img {
  padding-right: 10px;
}
.jss55>div>p {
  margin: 0;
}
.jss55>div>p:first-child {
  color: #658F9B;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.25px;
}
.jss55>div>p:last-child {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
}
.jss56 {
  color: #FF637B;
  display: flex;
  padding: 13px 21px;
  font-size: 12px;
  align-items: center;
  font-weight: 500;
  line-height: 16px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}
.jss56>img {
  padding-right: 10px;
}
.jss57 {
  display: flex;
  padding: 20px 0px;
  align-items: flex-start;
  flex-direction: column;
}
.jss58 {
  display: flex;
  align-items: center;
}
.jss58 span {
  display: flex;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  padding-right: 6px;
}
.jss58 h5 {
  color: #02475B;
  width: 100%;
  margin: 0;
  font-size: 10px;
  text-align: left;
  font-weight: 500;
  line-height: 13px;
}
@media (max-width:767.95px) {
  .jss58 h5 {
    max-height: 17px;
    min-height: 17px;
  }
}
.jss58 h5 span {
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  padding-left: 3px;
}
.jss58 h5 img {
  width: 23%;
  display: inline-block;
  margin-top: -1px;
  padding-left: 2px;
  padding-right: 2px;
  vertical-align: middle;
}
@media (max-width:767.95px) {
  .jss58 h5 img {
    width: 22%;
  }
}
.jss58 span:last-of-type {
  padding-right: 0;
}
.jss58 span:nth-of-type(1) {
  font-weight: 500;
}
.jss58 span:nth-of-type(2) {
  font-weight: 500;
}
.jss59 {
  opacity: 0.6;
  font-size: 16px !important;
  line-height: 16px;
  letter-spacing: 0.35px;
  text-decoration: line-through;
}
.jss60 {
  opacity: 0.6;
  font-size: 16px !important;
  line-height: 16px;
  letter-spacing: 0.35px;
}
.jss61 {
  color: #00B38E !important;
}
.jss61 img {
  width: 28px;
  margin-right: 5px;
}
.jss62 {
  color: #02475B;
  display: flex;
  margin-top: auto;
  align-items: center;
  flex-direction: row;
}
.jss62 div {
  width: unset;
}
.jss62>div:first-child {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.35px;
}
.jss63 {
  top: 3px;
  color: #02475B;
  opacity: 0.87;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
}
.jss64>div {
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
  letter-spacing: 0.35px;
}
.jss64>div>span {
  color: #02475B;
  opacity: 0.6;
  font-size: 16px;
  text-align: right;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.35px;
  text-decoration: line-through;
}
.jss64>div>div {
  color: #00B38E;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  padding-left: 4px;
}
.jss65 {
  align-self: flex-end;
}
.jss65>div {
  font-size: 12px;
  text-align: right;
  font-weight: 500;
  line-height: 16px;
}
.jss65>div>img {
  width: 23%;
  padding-left: 3px;
  padding-right: 3px;
  vertical-align: middle;
}
.jss66 h2 {
  margin: 0 0 15px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
}
.jss66 ul {
  color: #007C9D;
  margin: 0;
  padding-left: 21px;
}
.jss66 ul li {
  padding: 5px 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-transform: capitalize;
}
@media (max-width:767.95px) {
  .jss66 ul li {
    font-size: 14px;
  }
}
.jss66 ul li ul li {
  padding: 2px 0px;
  font-size: 12px;
  list-style: disc;
  font-weight: normal;
  line-height: 16px;
}
@media (max-width:767.95px) {
  .jss66 ul li ul li {
    font-size: 11px;
  }
}
@media (max-width:767.95px) {
  .jss66 h2 {
    font-size: 20px !important;
  }
}
.jss67 {
  padding: 2px 20px !important;
}
@media (max-width:767.95px) {
  .jss67 {
    padding: 10px 20px !important;
  }
}
.jss67 ul {
  margin: 0;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
}
.jss67 ul li {
  display: inline-flex;
  list-style: none;
  align-items: center;
  flex-direction: row;
}
.jss67 ul li>div>p {
  margin: 4px 0 0 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: -0.2px;
}
.jss67 ul li>div>div {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.5px;
}
@media (max-width:767.95px) {
  .jss67 ul li>div:nth-of-type(3) {
    flex-basis: 100%;
    text-align: center;
  }
}
@media (max-width:767.95px) {
  .jss67 ul li>div:nth-of-type(3)>img {
    transform: rotate(90deg);
  }
}
.jss67 ul li>div:last-child img {
  padding-left: 10px;
}
.jss67 ul li>div:first-child img {
  padding-right: 10px;
}
.jss69>div {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 10px;
  letter-spacing: 0.25px;
}
@media (max-width:767.95px) {
  .jss69>div {
    font-size: 14px;
  }
}
.jss70 {
  text-align: right;
  margin-bottom: 0px;
}
.jss70 button {
  color: #fcb716;
  padding: 0;
  background: transparent !important;
  box-shadow: unset;
}
.jss71 {
  padding-bottom: 10px;
}
.jss72 {
  margin-bottom: -20px;
}
.jss72 button {
  width: 100%;
  height: 50px;
  font-size: 16px;
  border-radius: 0px 0px 10px 10px;
}
.jss73 {
  padding: 7px 20px;
}
.jss73>div>p>span {
  font-size: 13px;
  padding-left: 10px;
}
.jss75 {
  height: 160px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}
.jss76 {
  padding-bottom: 22px;
}
@media (max-width:767.95px) {
  .jss76:last-child {
    padding-bottom: 70px;
  }
}
.jss77 {
  color: #02475b;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 8px;
  text-transform: capitalize;
  justify-content: space-between;
}
@media (max-width:767.95px) {
  .jss77 {
    margin-left: 20px;
  }
}
.jss77 h2 {
  font-size: 14 !important;
  font-weight: 600;
}
.jss78 {
  text-align: right;
}
.jss78 button {
  color: #FC9916;
  box-shadow: none;
}
.jss78 button:hover {
  background: none;
}
.jss79 {
  width: 100%;
  border-top: 1px solid rgba(0,0,0,.1);
  margin-top: 35px;
  padding-top: 35px;
}
.jss80 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
@media (max-width:767.95px) {
  .jss80 {
    align-items: flex-start;
    flex-direction: column;
  }
}
.jss80 p {
  margin: 4px 0 0 0;
  font-size: 10px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: -0.2px;
}
.jss80 div {
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.5px;
}
.jss81 {
  top: -10px;
  color: #02475B;
  position: relative;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  margin-bottom: 10px;
}
