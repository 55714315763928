.header-bottom{
    border-bottom: 1px solid #efefef;
    .category{
        border-right: 1px solid #efefef;
        p,.icon{
            font-size: 14px;
        }
        &:hover{
            background-color: #426af4;
            transition-duration: 0.3s;
            color: #fff;
        }
    }
    .menus{
        a{
            font-size: 14px;
            color: rgba(0, 0, 0, 0.87);
            &:hover{
                color: #426af4;
                transition-duration: 0.3s;
                text-decoration: none;
			}
        }
    }
    .cart{
        background-color: #426af4;
        p{
            font-size: 14px;
            color: #fff;
            .icon{
                font-size: 17px;
            }
        }
    }
}