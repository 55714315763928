* {
    font-family: 'Roboto', sans-serif !important;
}
p,hr {
    margin: 0;
    padding: 0;
}

.bg-white {
    background-color: #fff;
}

.bg-light-grey {
    background-color: #f7f7f7;
}

.bg-theme {
    background-color: #426af4;
}

.cursor-pointer {
    cursor: pointer;
}

.f-10 {
    font-size: 10px;
}

.f-13 {
    font-size: 13px !important;
}

.f-14 {
    font-size: 14px !important;
}

.f-15 {
    font-size: 15px !important;
}

.f-25 {
    font-size: 25px !important;
}

.form-control, .form-control:focus {
    color: #2b2f4c !important;
    background-color: #f7f7f7 !important;
    font-size: 14px !important;
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
    border-radius: 0.3rem !important;
}

.form-control:-moz-focusring {
    text-shadow: none !important;
}

.rec-arrow {
    border-radius: 50%!important;
    background-color: #ffffff!important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07)!important;
    width: 30px!important;
    height: 30px!important;
    min-width: 30px!important;
    min-height: 30px!important;
    font-size: 10px!important;
    line-height: 30px!important;
}

.rec-slider-container {
    margin: 0!important;
}

.rec-item-wrapper {
    height: 100%!important;
}

.text-theme {
    color: #426af4!important;
}

.w-10 {
    width: 10%!important;
}

.w-20 {
    width: 20% !important;
}

.w-15 {
    width: 15%!important;
}

.w-35 {
    width: 35%!important;
}

.w-40 {
    width: 40%!important;
}

.w-60 {
    width: 65%!important;
}

.w-80 {
    width: 80%!important;
}

.w-85 {
    width: 85%!important;
}

.word-nowrap{
    white-space: nowrap;
}